import React from 'react'
import "./Header.style.scss"
import { Link } from "gatsby"

//ヘッダーのコンポーネント

const Header = () => {
    return (
        <header className="header ph">
            <div className="header_logo_box">
                <img className="header_logo img_color" src="https://spin-dd.com/wp-content/uploads/2020/03/spin_logo_w.png" alt="会社のロゴです" />
                <nav className="nav-container">
                    <ul className="ui-container">
                    <li className="nav-item home-visited"><button><Link to ="/">HOME</Link></button></li>
                    <li className="nav-item home-visited"><button><Link to ="/about">ABOUT</Link></button></li>
                    <li className="nav-item home-visited"><button><Link to ="/services">SERVICES</Link></button></li>
                    <li className="nav-item home-visited"><button><Link to ="/works">WORKS</Link></button></li>
                    <li className="nav-item home-visited"><button><Link to ="/blog">BLOG</Link></button></li>
                    <li className="nav-item home-visited"><button><Link to ="/contact">CONTACT</Link></button></li>
                    </ul>
                </nav>
            </div>
            <div className="header__text_box">
                <h1 className="heading_primary">
                    <span className="heading_primary__main">increase work productivity</span>
                    <span className="heading_primary__sub">Interior remodeling to makers</span>
                </h1>
                <button className="header_button btn__animated"><Link to ="/about">Our Portfolio</Link></button>
            </div>
        </header>
    )
}  

export default Header